/*Services*/
.service_thumb_box{
    position: relative;
    .service_thumb_content{
        position:absolute;
        top:0px;
        left:0px;
        padding:40px;
        h4{
            margin-bottom:10px;
        }
    }
}
.services_list{
    @include category_list($blue, darken($blue, 10%));
}
