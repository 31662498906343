/*Advice Center*/
.advice_list{
    @include category_list(#161b1f, lighten(#161b1f, 10%));
}
.advice_item{
    margin-bottom:40px;
    .advice_key{
        color:$blue;
        @extend .prox_bold, .largest_font;
        line-height: 1em;
    }
    &:nth-child(2n+1){
        clear:both;
    }

}
.advice_key_1,
.advice_key_4,
.advice_key_5,
.advice_key_8,
.advice_key_9,
.advice_key_12,
.advice_key_13,
.advice_key_16,
.advice_key_17,
.advice_key_20,
.advice_key_21{
    color:$red!important;
}
