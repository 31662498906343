$red:#ce1330;
$blue:#143f6b;
$grey:#efefef;
$dark_grey:#626262;
$contact_grey:#808080;
.red{
    color:$red;
}
.blue{
    color:$blue;
}
.blue_bg{
    background:$blue;
    color:white;
    a{
        color:white;
    }
}
.red_bg{
    background:$red;
    color:white;
    a{
        color:white;
    }
}
.dark_grey{
    color:$dark_grey;
}
.overlay{
    position:absolute;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
    opacity:0.7;
}
.blue_overlay, .red_overlay{
    @extend .overlay;
}
.blue_overlay{
    background:$blue;
}
.red_overlay{
    background:$red;
}
.black_overlay{
    @extend .overlay;
    background:black;
}
.grey_bg{
    background:$grey;
}
.contact_grey_bg{
    background:$contact_grey;
}
