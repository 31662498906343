.redalert_flash{
    width:100%;
    max-width:1400px;
    display:block;
    margin:0 auto 120px;
}
.security_solution_box{
    margin-bottom:60px;
    h1{
        @extend .large_para_font;
        margin-top:40px;
    }
    p{
        font-size:1.2em;
    }
    &:nth-child(3n + 1){
        clear: both;
    }
}
.security_icon{
    max-width:249px;
    width:100%;
}
.single_security_icon{
    padding-left:30px;
    padding-bottom:30px;
}
.home_image_container{
    position: relative;
    .home_image_content{
        color:white;
        @extend .centered_absolute;
        top:50%;
        transform:translate(-50%,-50%);
        div:first-child{
            max-width:790px;
            padding:20px 20px 20px 0;
            p{
                @extend .prox_thin;
            }
        }
    }
}
.white_down_arrow{
    position:absolute;
    bottom:20px;
    left:50%;
    transform:translate(-50%, 0);
    z-index: 100;
}
.home_bottom_content{
    position:absolute;
    top:50%;
    left:0px;
    padding:40px;
    color:white;
    width:100%;
    transform:translate(0, -50%);
    p{
        @extend .heading_three_font;
    }
}
.bottom_ra_logo{
    width:100%;
    max-width: 174px;
    position:absolute;
    top:50%;
    transform:translate(0, -50%);
    right:40px;
}
.testimonial_background_slider{
    padding:40px;
    text-align: center;
    ul{
        width:50%;
    }
    li{
        margin-bottom: 0px!important;
    }
    .signoff{
        font-family: 'Proxima';
        margin-bottom:0px;
        margin-top:20px;
    }
    .bx-wrapper {
        position: relative;
        padding: 0;
        box-shadow: 0 0 0px $blue!important;
        border:none;
        background:$blue!important;
    }
}
.visit_telecare_link{
    background:black;
    padding:30px 0;
    text-align: center;
    h3{
        margin:0px;
    }
    a{
        color:white;

    }
}
