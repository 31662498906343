/*Responsive*/
.responsive_two, .responsive_three, .home_graphic_four{
    display:none!important;
}
.responsive_hide{
    display: none;
}
.nav_break{
    display:block;
}
@media (max-width:2000px){
    // .navigation_top{
    //     li{
    //         margin-right:30px;
    //     }
    // }
}
@media (max-width:1700px){

}
@media (max-width:1550px){
    .navigation_top{
        li{
            margin-right:45px;
        }
    }
    .sticky_side{
        img{
            width:30px;
            margin-bottom:0px;
        }
        -webkit-box-shadow: 11px 3px 0px 0px rgba(50, 50, 50, 0.20);
        -moz-box-shadow:    11px 3px 0px 0px rgba(50, 50, 50, 0.20);
        box-shadow:         11px 3px 0px 0px rgba(50, 50, 50, 0.20);

        right:auto;
        left:0px;
        width:100%;
        top:auto;
        bottom:0px;
        z-index:100;
        padding:5px 0 10px;
        font-size:1.2em;
        text-align:center;
        transform:translate(0%,0%);
        p{
            margin-bottom:0px;
            position:relative;
            top:2px;
            display:inline;
        }
        *{
            color:white;
        }
    }
}

@media (max-width: 1414px){
    .redalert_flash{
        margin-bottom:0px;
    }
}
@media (max-width: 1350px){
    .responsive_one{
        display:none!important;
    }
    .responsive_two{
        display:block!important;
    }
    .about_middle{
        position: relative;
        .about_middle_content{
            font-size:1.3em;
        }
    }
    .navigation_top{
        li{
            margin-right:30px;
        }
    }
    #share{
        top: 159px;
        right: 0px;
        padding-right: 7px;
        padding-left: 3px;
        .jssocials-share{
            display:inline-block;
            font-size: 14px;
        }
    }
    .head_nav{
        width:100%;
    }
    .security_solutions_header{
        h1{
            font-size: 2em;
        }
        p{
            font-size:0.8em;
        }
    }
}
@media (max-width: 1220px){

}

@media (max-width: 1153px){
    .nav_container{
        height:118px;
    }
    .website_content{
        margin-top:118px;
    }
    .navigation_top{
        li{
            font-size:0.85em;
        }
    }
    #share{
        top: 151px;
    }
    .home_slider{
        .slide_title{
            font-size:3em!important;
            line-height:1em!important;
            p{
                line-height:1.3em!important;
            }

        }
    }
    .slide_ra_logo{
        max-width: 200px!important;
    }
    .client_title{
        h1{
            font-size: 1.5em!important;
        }
    }
    .slide_global_content{
        .largest_font{
            line-height:1em;
            font-size:3em;
        }
    }
}
@media (max-width: 1024px){
    .navigation_top{
        li{
            margin-right:30px;
        }
    }

    .advice_key{
        font-size:3.5em!important;
    }
    .service_thumb_box{
        width:50%;
        &:last-child{
            width:100%;
        }
    }
}
@media (max-width: 1010px){
    .nav_container{
        height:65px;
    }
    .head_nav{
        padding:10px 0;
        .ra_logo, .menu_search, .navigation_top{
            // display:inline;
        }
    }
    .website_content{
        margin-top:66px;
    }
    .ra_logo{
        width:140px!important;
        margin-top:5px;
    }

    .navigation_top{
        background:$blue;
        position:fixed;
        right:0px;
        margin-right:-400px;
        top:0px;
        bottom:0px;
        z-index:2;
        text-align:left!important;
        padding:20px;
        width:100%;
        opacity:0.9;
        max-width:400px;
        transition:margin 0.6s ease;
        overflow-x:auto!important;

        li{
            display:block;
            margin-right:0px;
            height:auto;
            color:white;
            text-align:left!important;
            font-size:1.4em;
            padding:5px 0;
            a{
                color:white;

                display: block;
                text-align:left!important;
            }
            ul{
                display: none!important;
                margin-top:0px!important;
            }
        }
    }
    .navigation_top > li:nth-child(7){
        ul{
            display:block!important;
            background:none!important;
            font-size:0.5em;
            position:relative!important;
            left:10px!important;
            padding-top:10px!important;
            padding-bottom:10px!important;
            ul{
                display:none!important;
            }
        }
    }
    #share{
        top:90px;
    }
    .show_responsive_nav{
        margin-right:0px;
        .responsive_hide{
            color:white;
            border:1px solid white;
            width:40px;
            height:40px;
            line-height: 40px;
            text-align: center;
            border-radius:50px;
            float:right;
            background:$blue;
            opacity: 1;
            cursor: pointer;
            position: relative;
            z-index: 10;
            display:block;
        }
    }

    .menu_search{
        display:none;
    }
    .nav_button{
        position:fixed;
        top:11px;
        right:15px;
        display:block;
        background:lighten($blue, 15%);
        transition:background 0.2s ease;
        cursor: pointer!important;
        &:hover{
            background:$blue;
        }
        width:50px;
        border-radius: 4px;
        padding:10px;
        float:right;
        .line{
            cursor: pointer!important;
            height:1px;
            width:100%;
            background:white;
            margin-bottom:5px;
            &:last-child{
                margin-bottom: 0px;
            }
        }
    }
    .slide_global_content{
        .largest_font{
            font-size:3em;
        }
    }
    .nav_break{
        display:inline-block;
    }
}

@media (max-width: 1000px){
    .advice_key{
        font-size:2.8em!important;
    }
    .client_title{
        h1{
            font-size: 1.2em!important;
        }
    }
}
@media (max-width: 900px){
    .slide_global_content{
        display:none;
    }
    .sec_alarm_bottom_content{
        font-size: 0.8em;
    }
    .footer_address_line{
        text-align: left!important;
        .comma{
            display:none;
        }
        .footer_responsive_break{
            display: block;
        }
    }
    .ra_footer_logo, .ra_copyright_award{
        width:100%;
    }
    .ra_footer_logo{
        position:relative;
        top:2px;
    }
    .footer_right{
        span{
            display: block;
            position:relative;
            top:-5px!important;
        }

    }
    .footer_right_image{
        margin-left:0px!important;
        width:90%!important;
        float:left!important;
        margin-bottom:60px;
    }
}
@media (max-width: 768px){
    .responsive_two{
        display:none!important;
    }
    .responsive_three{
        display:block!important;
    }
    .security_solution_box{
        width:50%;
        margin-bottom:20px;
        &:nth-child(3n + 1){
            clear: none;
        }
        &:nth-child(2n+1){
            clear:both;
        }
    }
    .accreditation_box{
        width:48.9%;
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }
    .accreditation_thumb:nth-child(2n){
        .accreditation_box{
            margin-right:0px;
        }
    }
    .main_right_content{
        h1:first-child{
            font-size: 2.7em;
        }
    }
    .client_title{
        h1{
            font-size:1.5em!important;
        }
    }

}
@media (max-width: 767px){
    .slide_global_content{
        display:block;
    }
    .about_middle{
        position: relative;
        .about_middle_content{
            font-size:1em;
        }
    }
    .history_image_one{
        margin:30px 0 0;
    }
    .history_image_two{
        margin:0 0 30px;
    }
    .security_other_alarm{
        display:none;
    }
    .main_right_content{
        margin-top:40px;
    }
    .advice_item{
        .col-xs-10{
            margin-bottom: 20px;
        }
    }
    .news_story_content{
        .news_date_category{
            margin-top:40px;
        }
    }
    .service_thumb_box{
        width:100%;
    }
    .services_another_supplier{
        display:none;
    }
    .cs_primary_paragraph{
        text-align: left;
        border-top:1px solid #c4c4c4;
        margin-top:20px;
        padding-top:20px;
    }
    .under_list_responsive_content{

        border-top:1px solid #c4c4c4;

        padding-top:20px;
    }
    .security_responsive_content{
        @extend .under_list_responsive_content;
        padding-top:30px;
    }
}
@media (max-width:725px){
    .accreditation_box{
        width:100%;
        margin-right:0px;
        max-width:400px;
        margin-bottom:15px;
    }

}
@media (max-width:600px){
    .testimonial_content{
        font-size:0.9em;
    }
    .client_box{
        width:100%;
        margin-bottom:15px;
    }
    .client_title{
        h1{
            font-size:1.2em!important;
        }
    }
    #share{

        top:66px;
        .jssocials-share {
            font-size: 10px;
        }
    }
    .home_bottom_content{
        h1{
            font-size:2em;
        }
        p{
            font-size:1.6em!important;
        }
    }
    .navigation_top{
        li{
            ul{
                li{
                    ul{
                        max-width:95%;
                    }
                }
            }
        }

    }
    .page_padding{
        padding:40px 0;
    }
    .page_top_padding{
        padding-top:40px;
    }
    .page_bottom_padding{
        padding-bottom:40px;
    }
    /*fonts*/
    .largest_font{
        font-size:3em;
    }

    .heading_font{
        font-size:2.3em;
    }
    .heading_two_font{
        font-size:2.0em; /*Approx 50px based on 18px body font size*/
    }
    .heading_three_font{
        font-size:1.7em;
        line-height: 1.1em;
    }
    .large_para_font{
        font-size:1.1em; /*Approx 30px based on 18px body font size*/
    }
    .med_para_font{
        font-size:1.3em; /*Approx 25px based on 18px body font size*/
    }
    .small_para{
        font-size:0.8em; /*Approx 14px based on 18px body font size*/
    }
    .slide_global_content{
        display:none;
        top:40px;
        bottom:auto;
        color:white;
        .slide_ra_logo{
            display:none;
        }
        p{
            margin-bottom:10px;
            font-size:14px;
        }
        .largest_font{
            line-height:1em;
        }
        a{
            color:white;
            font-size:18px;
            font-family:'Proxima';
        }
    }
    .home_slider{
        .slide_title{
            font-size:2em!important;
            line-height:1em!important;
            p{
                line-height:1.3em!important;
            }

        }
    }
    .security_solution_box{
        width:100%;
        margin-bottom:40px;
    }
    .security_icon{
        max-width:200px;
    }
    .home_image_container{
        .home_image_content{
            div:first-child{
                max-width:100%;
                padding:20px 20px 20px 0;
                h1{
                    font-size:2em;
                }
                p{
                    font-size:0.8em;
                }
            }
        }
    }
    .main_right_content{
        h1:first-child{
            font-size:2em;
        }
    }
    .client_box_no_image{
        img{
            height:50px;
        }
    }
    .footer{
        .col-sm-3{
            text-align: left!important;
        }
        .footer_content{
            padding:40px 0 80px;
        }
        .footer_right{
            text-align: left;
        }
    }
    .service_thumb_box{
        *{
            font-size:1em;
        }
        font-size:1em!important;
    }
    .service_contact_us{
        display:none;
    }
    .home_graphic_three{
        display:none!important;
    }
    .home_graphic_four{
        display:block!important;
    }
    .security_solutions_header{
        h1{
            font-size: 1.8em;
        }
        p{
            font-size:0.7em;
        }
    }
}
@media (max-width:500px){
    .nav_container{
        height:66px;
    }
    .ra_logo{
        width:120px!important;
        margin-top:7px;
    }
    .about_slider h1 {
        font-size:2.2em;
    }
    .about_middle{
        position: relative;
        .about_middle_content{
            font-size:0.9em;
        }
    }
    .home_bottom_content{
        h1{
            font-size:1.4em;
        }
        p{
            font-size:1em!important;
        }
    }
    .security_solutions_header{
        h1{
            font-size: 1.4em;
        }
        p{
            font-size:0.6em;
        }
    }
}
