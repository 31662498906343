
/*Head/Nav*/
.nav_container{
    position:fixed;
    z-index:1000;
    background:white;
    width:100%;
    top:0px;
    right:0px;
    left:0px;
    height:124px;
    @extend .subtle_bottom_shadow;
}
.head_nav{
    padding:40px 0;
    .menu_search{
        cursor: pointer;
        position: relative;
        top:-5px;
    }

    .ra_logo{
        width:100%;
        max-width:174px;
        position: relative;
        top:-5px;
    }
    width:95%;
    max-width:1600px;
    margin:auto;
}
.navigation_search{
    position: relative;
    top:5px;
    display:none;
    width:80%;
    margin:auto;
    button{
        position:absolute;
        right:0px;
        top:0px;
        color:white;
        background:$blue;
        transition:background 0.2s ease;
        &:hover{
            color:white;
            background:lighten($blue, 10%);
        }
    }
}
.navigation_top{
    text-align: center;
    margin:auto;
    position: relative;
    top:0px;
    li{
        display:inline-block;
        margin-bottom:0px;
        margin-right:70px;
        position: relative;
        vertical-align: top;
        position: relative;
        height:95px;
        line-height: 1.2em;
        a{
            display:block;
            text-align: center;
        }
        &:last-child{
            margin-right:0px;
        }
        .subnav_top{
            background:$blue;

            left:-10px;
            display:none;
            position: absolute;
            margin-top:41px;
            min-width:200px;
            li{
                display:block;
                position: relative;
                line-height: 1em;
                height:auto;
                text-align: left!important;
                padding:10px;
                width:100%;
                transition:background 0.2s ease;
                &:hover{
                    background: lighten($blue, 5%);
                }
                a{
                    text-align: left!important;
                    color:white;

                }
                ul{
                    background: lighten($blue, 15%);
                    position: absolute;
                    transition:opacity 0.2s ease;
                    top:-8px;
                    left:0px;
                    font-size:0.8em;
                    // display:block;
                }
                &:hover > ul{
                    display:block;
                }
                &:last-child{
                    margin-bottom:0px;
                }
            }

        }
        &:hover > ul{
            display:block;
        }
    }
}
