@font-face {
    font-family: 'ProximaSemi';
    src: url('../typography/proximanova-sbold.eot');
    src: url('../typography/proximanova-sbold.eot?#iefix') format('embedded-opentype'),
         url('../typography/proximanova-sbold.woff') format('woff'),
         url('../typography/proximanova-sbold.ttf') format('truetype'),
         url('../typography/proximanova-sbold.svg#ProximaNovaLtSemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaThin';
    src: url('../typography/proximanova-thin.eot');
    src: url('../typography/proximanova-thin.eot?#iefix') format('embedded-opentype'),
         url('../typography/proximanova-thin.woff') format('woff'),
         url('../typography/proximanova-thin.ttf') format('truetype'),
         url('../typography/proximanova-thin.html') format('html');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ProximaBold';
    src: url('../typography/proximanova-bold.eot');
    src: url('../typography/proximanova-bold.eot?#iefix') format('embedded-opentype'),
         url('../typography/proximanova-bold.woff') format('woff'),
         url('../typography/proximanova-bold.ttf') format('truetype'),
         url('../typography/proximanova-bold.svg#ProximaNovaRgBold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Proxima';
    src: url('../typography/proximanova-reg.eot');
    src: url('../typography/proximanova-reg.eot?#iefix') format('embedded-opentype'),
         url('../typography/proximanova-reg.woff') format('woff'),
         url('../typography/proximanova-reg.ttf') format('truetype'),
         url('../typography/proximanova-reg.svg#ProximaNovaRgRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_novalight';
    src: url('../typography/proximanova-light-webfont.eot');
    src: url('../typography/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../typography/proximanova-light-webfont.woff') format('woff'),
         url('../typography/proximanova-light-webfont.ttf') format('truetype'),
         url('../typography/proximanova-light-webfont.svg#proxima_novalight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_novasemibold';
    src: url('../typography/proximanova-semibold-webfont.eot');
    src: url('../typography/proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../typography/proximanova-semibold-webfont.woff') format('woff'),
         url('../typography/proximanova-semibold-webfont.ttf') format('truetype'),
         url('../typography/proximanova-semibold-webfont.svg#proxima_novasemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* Generated by Font Squirrel (http://www.fontsquirrel.com) on November 12, 2014 */
@font-face {
    font-family: 'proxima_novalight_italic';
    src: url('../typography/proximanova-lightitalic-webfont.eot');
    src: url('../typography/proximanova-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../typography/proximanova-lightitalic-webfont.woff2') format('woff2'),
         url('../typography/proximanova-lightitalic-webfont.woff') format('woff'),
         url('../typography/proximanova-lightitalic-webfont.ttf') format('truetype'),
         url('../typography/proximanova-lightitalic-webfont.svg#proxima_novalight_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_novaregular_italic';
    src: url('../typography/proximanova-regitalic-webfont.eot');
    src: url('../typography/proximanova-regitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../typography/proximanova-regitalic-webfont.woff2') format('woff2'),
         url('../typography/proximanova-regitalic-webfont.woff') format('woff'),
         url('../typography/proximanova-regitalic-webfont.ttf') format('truetype'),
         url('../typography/proximanova-regitalic-webfont.svg#proxima_novaregular_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_novasemibold_italic';
    src: url('../typography/proximanova-semibolditalic-webfont.eot');
    src: url('../typography/proximanova-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../typography/proximanova-semibolditalic-webfont.woff2') format('woff2'),
         url('../typography/proximanova-semibolditalic-webfont.woff') format('woff'),
         url('../typography/proximanova-semibolditalic-webfont.ttf') format('truetype'),
         url('../typography/proximanova-semibolditalic-webfont.svg#proxima_novasemibold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}
