/*Footer*/
.footer{
    border-top:1px solid #ccc;
    .footer_content{
        padding:40px 0 150px;
        .footer_address_line{
            text-align: right;
        }
        .footer_right{
            text-align: left;
        }
    }
    ul{
        margin-bottom:20px;
        li{
            margin-bottom:0px;
        }
    }
    .footer_right{
        margin-top: 18px;
        @extend .small_para;
        span{
            position:relative;
            top:13px;
        }
        .footer_right_image{
            margin-left:3%;
            width:75%;
            
            float: right;
        }
    }
    .cube_logo{
        position: relative;
        top:-2px;
    }
}
