/*About*/
.top_slider{
    position:relative;
}
.about_slider{
    width:100%;
    max-width:1200px;
    padding: 0 15px;
    opacity: 0.9;
    h1{
        margin:0px;
        padding:10px 30px;
    }
    h1:first-child{
        background:$red;
        display:inline-block;
        margin-bottom:10px;
    }
    h1:last-child{

        background:$blue;
        display:inline-block;
    }
}
.about_middle{
    position: relative;
    .about_middle_content{
        @extend .absolutely_centered;
        max-width:1200px;
        width:100%;
        padding:0px 15px;
    }
}
