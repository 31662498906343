.plain_bx_slider{
    .bx-wrapper {
        position: relative;
        margin: 0 auto;
        padding: 0;
        box-shadow: 0 0 0px #fff!important;
        border:none;
        background: #fff;
    }
}

/*Home*/
.plain_bx_slider{
    position:relative;
}
.home_slider{
    li{
        position: relative;
        .slide_title{
            @extend .centered_absolute, .largest_font, .prox_thin;
            top:50px;
            line-height:90px;
            p{
                margin:0px;
                line-height:90px;
            }
        }
    }
}
.slide_global_content{
    @extend .centered_absolute,  .prox_thin;
    top:auto;
    bottom:50px;
    color:white;
    .slide_ra_logo{
        position: relative;
        top:-15px;
        width:100%;
        max-width:300px;
    }
    p{
        margin-bottom:10px;
        position: relative;
        top:-30px;
    }
    .largest_font{
        line-height:1em;
    }
    a{
        color:white;
        font-size:18px;
    }
}
