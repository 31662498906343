@import 'reset';
@import 'general_styles';
@import 'bootstrap';
@import 'typography';
@import 'colors';
@import 'fonts';
body{
    font-family: 'Proxima';
    font-size:18px;
    color:#191c20;
}
#share{
    position:fixed;
    z-index: 300;
    background:white;
    top:20%;
    //transform:translate(0, -50%);
    .jssocials-share{
        display:block;
    }
    .jssocials-share:first-child {
        margin-left: 0.3em;
    }
}
.sticky_side{
    img{
        width:50px;
        margin-bottom:5px;
    }
    background:$blue;
    position:fixed;
    right:0px;
    top:20%;
    //transform:translate(0, -50%);
    z-index:100;
    padding:20px;
    font-size:1.3em;
    p{
        margin-bottom:0px;
    }
    *{
        color:white;
    }
}
.website_content{
    margin-top:126px;
}
@import 'containers';
@import 'head_nav';
@import 'sliders';
@import 'homepage';
@import 'about';
@import 'security_solutions';
@import 'advice_centre';
@import 'services';

.news_date_category{
    font-size:1em;
}
.news_title{
    font-size:2em;
}
/*News*/
.news_story_container{
    border-bottom:1px solid #e7e7e5;
    &:last-child{
        border-bottom: none;
    }
    p:last-child{
        margin-bottom:0px;
    }


    &:nth-child(4n+1){
        .news_title{
            color:#c0747e;
        }
    }
    &:nth-child(5n+2){
        .news_title{
            color:#6f939d;
        }
    }
    &:nth-child(6n+3){
        .news_title{
            color:#e6ae79;
        }
    }
}
/*Testimonials*/
.testimonial_container{
    min-height:300px;
    position:relative;
    background:#f6f6f6;
    &:nth-of-type(3n+1){
        background:#8aaac6;
        color:white;
    }
    &:nth-of-type(3n+3){
        background:#e28493!important;
        color:white;
    }
    &:nth-child(2n+1){
        clear:both;
    }
    .testimonial_content{
        padding:40px;
        position: absolute;
        top:50%;
        transform: translate(0, -50%);
        .testimonial_title{
            margin-top:20px;
        }
    }
}

/*Clients*/
.clients_container{
    margin-top:80px;
    margin-bottom:80px;
}
.client_box{
    position:relative;
    margin-bottom:30px;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
        position: absolute;
        top:0px;
    }
    .client_title{
        // background: red;
        // height: 100%;

        h1{
            margin-bottom:0px;
            font-size: 2em;
            text-align: center;
            padding:15px;
            @extend .absolutely_centered;
        }
    }
}
.client_bottom{
    text-align:center;
    margin-top:40px;
    @extend .blue_bg;
    padding:40px;
}
/*Careers*/
.job_container{
    &:first-child{
        margin-top:60px;
    }
    &:last-child{
        padding-bottom: 0px;
    }
    border-top:1px solid #e7e7e5;
    .job_button{
        margin-top:60px;
        width:195px;
        text-align: center;
        transition:background 0.2s ease;
        a{
            color:white;
            display:block;
            @extend .prox_thin;
            padding:10px;
        }
    }
    .job_button_job{
        background:$red;
        &:hover{
            background: darken($red, 10%);
        }
    }
    .job_button_vacancy{
        background:$blue;
        &:hover{
            background: darken($blue, 5%);
        }
    }
}
/*Accreditations*/

.accreditation_box{
    margin-bottom:15px;
    position:relative;
    border:1px solid #dcdcdc;
    margin-right:15px;
    width:23.4%;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    img{
        @extend .absolutely_centered;
        width:90%;
        padding:15px;

    }
}
.accreditation_thumb:nth-child(4n){
    .accreditation_box{
        margin-right:0px;
    }
}

/*Case Studies*/
.case_studies_title{
    color:white;
    @extend .prox_bold, .vertically_centered;
    width:100%;
    padding:0px 15px;
    text-align:center;
}
.cs_primary_paragraph{
    text-align:center;
    @extend .large_para_font;
}
.case_studies_list{
    li{
        margin-bottom:0px;
        a{
            display:block;
            padding:0px 0px 5px;
            @extend .prox_light;
            &:hover{
                @extend .prox_bold;
            }
        }
        .active_item{
            @extend .prox_bold;
        }
    }
}

/*Contact*/
.contact_address_content{
    margin-bottom:20px;
    h3{
        font-size:1.6em;
        @extend .prox_bold;
        margin-bottom:5px;
        color:#303030;
    }
    p{
        font-size:1.2em;
        line-height: 1.2em;
        margin-bottom:0px;
        color:#808080;
    }
    a{
        color:#808080;
    }
}
.contact_form{
    h3{
        margin-bottom:20px;
    }
    *{
        color:white;
    }
    label{
        @extend .prox_thin;
    }
    input, textarea{
        color:$contact_grey;
        @extend .prox_light;
        border-radius:0px;
    }
}
.contact_form_container{
    margin-top:40px;
    .contact_submit{
        background:#5cb85c;
        border:none;
        color:white;
        width:100%;
        transition: background 0.2s ease;
        &:hover{
            background:darken(#5cb85c, 5%);
        }
    }
}
.validation_error_li{
    margin-bottom:0px;
    font-size:0.8em;
}
.user_guides{
    padding-left:30px;
    padding-right:30px;
    color:#2f2f2f;
    .user_guide_blurb{
        *{
            font-size:24px!important;
            line-height:34px;
        }
    }
    *{
        color:#2f2f2f;
    }
    .user_guide{
        margin-bottom: 60px;
        h1{
            max-width:90%;

        }
    }
    .PDF_dl{
        width:100%;
        padding:20px;
        max-width:249px;
    }
}
@import 'charity';
@import 'footer';
@import 'responsive';
