.external_container{
    max-width:1200px;
    margin:auto;
    position:relative;
}
.absolutely_centered{
    width:100%;
    max-width: 1160px;
}
.centered_absolute{
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    max-width: 1200px;
    padding:0px 15px;
    left:50%;
    transform: translate(-50%,0);
}
.page_padding{
    padding:60px 0;
}
.page_top_padding{
    padding-top:60px;
}
.page_bottom_padding{
    padding-bottom:60px;
}
.narrower_content_width{
    max-width:1100px;
    margin-left: auto;
    margin-right: auto;
}
