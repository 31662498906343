.charity_page{
	.container, .inner_content{
		position:relative;
		margin:auto;
	}
	.container{
		max-width:1440px;
		width:100%;
	}
	.inner_content{
		max-width:1200px;
	}
	.full_width_image{
		width:100%;
	}
	h1{
		font-size:60px;
		color:white;
	}
	p {
	    font-size: 14px;
	    line-height: 24px;
	    margin-bottom: 20px;
	}
	p:last-child{
		margin-bottom:0px;
	}
	.head_img{
		width:100%; /*This makes the image scale*/
	}
	.absolutely_centered{
		position:absolute;
		left:50%;
		top:50%;
		transform:translate(-50%,-50%);
	}
	.head{
		position: relative;
	}
	.head h1{
		z-index:1;
		width: 100%;
	    text-align: center;
	    padding:30px;
	}
	.clive{
		margin-top:100px;
	}
	.clive_text_box, .clive_image{
		position: relative;
		max-width:600px;
	}
	.clive_image{
		float:right;
	}
	.clive_text_box:before{
		content:'';
		padding-top: 87%;
	    display: block;
	    background-color: #f2f2f2;
	}
	.clive_text{
		position: absolute;
	    top: 0px;
	    padding: 30px;
	}
	.justgiving{
	    bottom: 0px;
	    margin: -25px auto 25px;
	    display: block;
	    position: relative;
	}
	.nolan{
		margin-top:50px;
		background-color:black;
		color:white;
		padding-top:70px;
		padding-bottom:70px;
		margin-left:0px;
		margin-right:0px;
	}
	.nolan_inner{
		max-width:1100px;
		margin:auto;
	}
	.no_logo, .no_text{
		padding-left:45px;
		padding-right:45px;
	}
	.no_logo img{
	    margin-bottom:70px;
	    display:block;
	    margin-left:auto;
	    margin-right:auto;
	}
	.why_anthony_nolan{
		background:white;
		color:black;
		padding:20px;
		font-size: 21px;
	}
	/*Sponsors*/
	.sponsor_list{
	    li{
	        position:relative;
	        height:100%;
			margin-bottom:60px;
	        .image_container{
	            border:1px solid #3d3d3d;
	            position:relative;
	            height:160px;
	            margin-bottom:30px;
	        }
	        img{
	            @extend .image_centered_in_div;
	            padding-top:30px;
	            padding-bottom:30px;
	        }
			.sponsor_content{
				*{
					
				}
			}
	        // .sponsor_link{
	        //     position:absolute;
	        //     bottom:0px;
	        // }
	    }
	    li:nth-child(3n + 1){
	        clear:both;
	    }
	}
}

@media (max-width: 992px){
	.charity_page{
		.container{
			max-width:768px;
		}
		h1{
			font-size:40px;
		}
		.clive{
			margin-top:50px;
		}
		.clive_text_box, .clive_image {
	    	max-width:100%!important;
		}
		.clive_text_box{
			width:100%;
			float:none;
		}
		.clive_image {
			width:100%;
	    	float:none;
		}
		.clive_image img{
			display:block;
			margin:auto;
			max-width:480px;
		}
		.nolan{
			margin-left:-15px;
			margin-right:-15px;
		}
		.clive_text_box:before{
			content:'';
			padding-top:0%;
		    background-color:none;
		}
		.clive_text{
			position: relative;
		    background-color: #f2f2f2;
		    padding-bottom:60px;
		}
	}
	
}
@media (max-width:767px){
	.charity_page{
		.no_logo, .no_text{
			padding-left:15px;
			padding-right:15px;
		}
		.no_text{
			margin-top:40px;
		}
		.no_logo img{
		    display:block;
		    margin:0 auto 50px;
		}
		.head h1{
			font-size:20px;
		}
	}
}
