
ul{
    margin:0px;
}
/*fonts*/
.largest_font{
    font-size:4em;
}
.text_shadow{
    text-shadow: 1px 1px #000000;
}
.heading_font{
    font-size:3.3em; /*Approx 60px based on 18px body font size*/
}
.heading_two_font{
    font-size:2.7em; /*Approx 50px based on 18px body font size*/
}
.heading_three_font, .page_title{
    font-size:2.2em; /*Approx 40px based on 18px body font size*/
    line-height: 1.1em;
}
.page_title{
    @extend .prox_light;
}
.large_para_font{
    font-size:1.6em; /*Approx 30px based on 18px body font size*/
    line-height: 1.2em;
}
.med_para_font{
    font-size:1.3em; /*Approx 25px based on 18px body font size*/
}
.small_para{
    font-size:0.8em; /*Approx 14px based on 18px body font size*/
}
.heading_bottom_margin{
    margin-bottom: 40px;
}
h1{
    @extend .heading_font;
    @extend .heading_bottom_margin;
    margin-top:0px;
}
h2{
    @extend .heading_two_font;
    @extend .heading_bottom_margin;
    margin-top:0px;
}
h3{
    @extend .heading_three_font;
    @extend .heading_bottom_margin;
    margin-top:0px;
}
h4{
    @extend .large_para_font;
    @extend .heading_bottom_margin;

    margin-top:0px;
}
h5{
    @extend .med_para_font;
    @extend .heading_bottom_margin;
    margin-top:0px;
}
p,li,a,div{

}
p, li, a{

}
p,li{
    margin-bottom:1em;
    line-height:1.5em;
}
a{
    transition:all 0.2s ease;
    color:#3d3d3d;
    &:hover{
        text-decoration: none;
    }
}
.no_margin_last_para{
    p:last-child{
        margin-bottom:0px;
    }
}
.prox_thin{
  font-family: 'ProximaThin';
  p,li,a,div,span{
     font-family: 'ProximaThin';
  }
}
.prox_light{
  font-family: 'proxima_novalight';
  p,li,a,div,span{
     font-family: 'proxima_novalight';
  }
}
.prox_semi{
  font-family: 'ProximaSemi';
}
.prox_bold, strong{
  font-family: 'ProximaBold'!important;
}
.prox_italic, em{
  font-family: 'proxima_novaregular_italic';
}
.prox_light_italic{
  font-family: 'proxima_novalight_italic';
}
.prox_semibold_italic{
  font-family: 'proxima_novasemibold_italic';
}
.shadow_text{
	text-shadow: -2px 2px 2px rgba(150, 150, 150, 0.61);
}
.para_font{
    @extend .prox_light;
}
.wysiwyg_content{
  a{
    color:#337ab7!important;
    &:hover{
      color:$blue!important;
    }
  }
    ul{
        list-style-type: disc;
        margin-left:15px;
        li{
            margin-bottom:10px;
        }
    }
}
